<template>
  <div>
    <app-detail-header
      :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <insurer-list-side-menu
          v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <!-- <div class="tile is-parent animated lightSpeedIn" v-show="toggle"> -->
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table
              class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr
                v-for="(insurer, index) in insurerSummaries"
                :key="insurer.insurerId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <a
                    @click.prevent="edit(insurer.insurerId, $event)"
                    :href="generateUrl(insurer.insurerId)">{{ insurer.name }}</a>
                </td>
                <td>{{ insurer.suburb }}</td>
                <td>{{ insurer.labourType }}</td>
                <td class="has-text-centered">{{ insurer.ecomTypeName }}</td>
                <td class="has-text-centered">
                  <span
                    class="tag"
                    :class="[ insurer.quotingMethod === 'D' ? 'is-success' : 'is-info' ]">{{ insurer.quotingMethod }}</span>
                </td>
                <td class="has-text-centered">
                  <span
                    class="icon"
                    :class="[ insurer.active ? 'has-text-success' : 'has-text-danger' ]">
                    <i
                      class="mdi mdi-24px"
                      :class="[ insurer.active ? 'mdi-check' : 'mdi-close' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <a
                    class="button is-primary is-small is-inverted tooltip"
                    @click.prevent="edit(insurer.insurerId, $event)"
                    :href="generateUrl(insurer.insurerId)"
                    data-tooltip="Click to edit">
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import InsurerListSideMenu from './InsurerListSideMenu'
import InsurerRoutes from './route-types'
import InsurerService from './InsurerService'
// import Guid from '@/components/Guid'
import { EventHubTypes, AppHeaderButtonTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import StoreMixin from './storeMixin'
import { InsurerPayload } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import _transform from 'lodash/transform'
import { Emailer } from '@/classes'

export default {
  name: 'InsurerListView',
  components: {
    AppDetailHeader,
    InsurerListSideMenu,
    BulmaTable
  },
  mixins: [StoreMixin],
  data() {
    return {
      totalRows: 0,
      insurerSummaries: {},
      filter: {
        name: '',
        suburb: '',
        state: '',
        labourType: '',
        ecomType: '',
        quotingMethod: '',
        isActive: 1,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    InsurerService.clearPreviousSessionStorage(this.$userInfo.sessionId)
    this.getData()
    this.$eventHub.$on(EventHubTypes.ToggleSideMenu, data => {
      this.toggle = false
      setTimeout(() => {
        this.toggle = true
      }, 200)
    })
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addStoreItem(new InsurerPayload())
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    async getData() {
      // this.$showSpinner()
      this.isTableLoading = true
      this.retrieveFilter()
      this.insurerSummaries = await InsurerService.getEntitySummaries(this.filter)
      this.totalRows = this.insurerSummaries.length > 0 ? this.insurerSummaries[0].totalRows : 0
      this.isTableLoading = false
      // console.log(this.insurerSummaries)
      // this.$hideSpinner()
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    generateUrl(id) {
      return `insurers/${id}`
    },
    edit(id, event) {
      this.$router.push({
        name: InsurerRoutes.InsurerDetail.name,
        params: { insurerId: id }
      })
    },
    print() {
      let params = Object.assign(
        {
          companyId: this.$userInfo.companyId
        },
        this.filter
      )
      params.sortColumn = this.filter.sortColumn.toUpperCase()
      params.pageIndex = 1
      params.pageSize = this.totalRows
      params = _transform(params, function(result, value, key) {
        result[key.toLowerCase()
        .replace('sortorder', 'sortdirection')
        .replace('labourtype', 'labourtimes')
        .replace('quotingmethod', 'quotemethod')
        .replace('isactive', 'active')] = value
      })

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Insurer Listing Report'
      emailer.reportName = 'rptInsurerList'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter.name = ''
      this.filter.suburb = ''
      this.filter.state = ''
      this.filter.labourType = ''
      this.filter.ecomType = ''
      this.filter.quotingMethod = ''
      this.filter.isActive = 1
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.zoomIn {
  animation: zoomIn 0.5s;
}
</style>
